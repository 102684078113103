@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  @apply font-sequel
}

select,
button {
  @apply font-sequel
}

.read-more-button {
  border-radius: 0.3125rem;
  box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.15);
  /* Button Text */
  font-size: 1.25rem;
  font-family: Sequel Sans;
  font-weight: 166;
  line-height: 1.625rem;
  letter-spacing: 0.05rem;
}

.slick-dots li:not(.slick-active) button:before {
  opacity: 1 !important;
  color: #fff !important;
}

[type='checkbox'],
[type='radio'],
[type='checkbox']:focus,
[type='radio']:focus {
  outline: 0px !important;
  outline-offset: 0px !important;
  --tw-ring-color: transparent !important;
  color: #8EFF95 !important
}

.bg-solar-energy {
  @apply relative bg-cover inset-0 bg-no-repeat;
}

.bg-solar-energy::before {
  content: "";
  @apply absolute inset-0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}


.dashboard-subtitle * {
  box-sizing: border-box;
}


.my-zembl,
.my-zembl * {
  box-sizing: border-box;
}

.my-zembl {
  color: var(--zembl-white, #fefefc);
  text-align: center;
  font: var(--headline-three, 400 32px/44px "Sequel Sans", sans-serif);
  position: relative;
}

.bg-dashboard-hero {
  @apply relative bg-cover inset-0 bg-no-repeat h-155;
}

.bg-dashboard-hero::before {
  @apply bg-zembl-forest;
  content: "";
  @apply absolute inset-0;
  z-index: 1;
}

.dashboard-above-shadow {
  @apply relative z-20 gap-12;
}

.bg-business-loan {
  @apply relative bg-cover inset-0 bg-no-repeat h-155;
}

.bg-business-loan::before {
  content: "";
  @apply absolute inset-0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.content-above-shadow {
  @apply relative z-20 gap-12;
}

.bg-solar-energy::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.home-header {
  @apply text-white font-sequel
}

.slick-dots {
  @apply flex;
  margin-left: 2.5rem !important;
  width: 9rem !important;
  bottom: -2.3rem !important;
}

@font-face {
  font-family: "Sequel Sans";
  src: url('./domain/assets/fonts/sequelsans-display.ttf');
}

.navbar-links {
  width: 100%;
}

@media (min-width: 640px) {
  .navbar-links {
    width: auto;
  }
}