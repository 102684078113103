 .scrollbar-hide::-webkit-scrollbar {
   display: none;
 }


 .scrollbar-hide {
   -ms-overflow-style: none;
   /* IE and Edge */
   scrollbar-width: none;
   /* Firefox */
 }

 .locked-a-deal-card-border {
   @apply w-5/6  text-zembl-violet text-center font-sequel text-[0.875rem] font-med-bold leading-[1.05rem];
   border-radius: 0.125rem;
   border: 2px solid var(--zembl-violet, #D8BCFF);
   display: flex;
   padding: 0.625rem 0.5rem;
   justify-content: center;
   align-items: center;
   gap: 0.625rem;

 }

 .locked-a-deal-card-text {
   display: flex;
   flex-direction: column;
   flex: 1 0 0;
   color: var(--zembl-violet, #D8BCFF);
   text-align: center;
   /* Status */
   font-size: 0.875rem;
   font-weight: 166;
   line-height: 120%;
   text-transform: uppercase;
 }

 .finding-a-deal-card-border {
   @apply w-5/6;
   border-radius: 0.125rem;
   border: 2px solid var(--zembl-ocean, #90A9B4);
   padding: 0.625rem 0.5rem;
   justify-content: center;
   align-items: center;
 }

 .finding-a-deal-card-text {
   color: var(--zembl-ocean, #90A9B4);
   text-align: center;
   /* Status */
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 166;
   line-height: 120%;
   text-transform: uppercase;
 }


 .found-a-deal-card-border {
   @apply w-5/6;
   border-radius: 0.125rem;
   background: var(--zembl-teal, #2C5D60);
   font-size: 2.25rem;
   font-weight: 166;
   line-height: 2.875rem;
   color: var(--zembl-forest, #19292C);
   text-align: center;
   text-transform: capitalize;
   display: flex;
   padding: 0.625rem 0.5rem;
   justify-content: center;
   align-items: center;
 }

 .found-a-deal-card-text {
   color: var(--zembl-green, #8EFF95);
   text-align: center;
   /* Status */
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 166;
   line-height: 120%;
   text-transform: uppercase;
 }

 .pending-card-text {
   @apply font-semibold;
   color: var(--zembl-teal, #2C5D60);
   text-align: center;
   /* Status */
   font-size: 0.875rem;
   line-height: 120%;
   text-transform: uppercase;
 }

 .pending-card-border {
   @apply w-5/6;

   border-radius: 0.125rem;
   border: 2px solid var(--zembl-teal, #2C5D60);
   display: flex;
   padding: 0.125rem 0.5rem;
 }

 .bill-upload-button-text {
   color: var(--zembl-green, #8EFF95);
   font-size: 1rem;
   font-family: Roboto !important;
   font-style: normal;
   font-weight: 500;
   line-height: 1.25rem;
   letter-spacing: 0.0125rem;
 }

 button[intent="bill-dark"] {
   @apply drop-shadow-xl;
   border-radius: 0.3125rem;
   background: var(--zembl-forest, #19292C);
   /* shadow */
   box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.15);
   display: flex;
   height: 3.25rem;
   padding: 1.25rem 0.9375rem;
   justify-content: center;
   align-items: center;
   flex-shrink: 0;
   align-self: stretch;
 }

 button[intent="billUpload"] {
   @apply w-5/6;
   display: flex;
   height: 3.25rem;
   padding: 1.25rem 0.9375rem;
   justify-content: center;
   align-items: center;
   border-radius: 0.3125rem;
   background: var(--zembl-white, #FEFEFC);
   box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.15);
 }

 .content {
   max-width: 1280px;
   overflow-x: hidden;
   margin: 0 auto;
 }

 .card {
   height: 100%;
   display: flex;
   flex-direction: column;
   max-width: 24rem;
   position: relative;
   border: 1px solid #ccc;
 }

 .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
 }

 .card-header span {
   color: #2d3748;
 }

 .card-image {
   width: 100%;
   height: 14rem;
   max-width: 100%;
   object-fit: cover;
 }

 .slider-wrapper {
   @apply w-full px-8;
 }

 .card button {
   display: inline-block;
   background-color: #14c8eb;
   color: #fff;
   border: none;
   padding: 1.5em 1.6em;
   cursor: pointer;
   text-decoration: none;
   font-size: 15px;
   font-family: inherit;
   width: 100%;
   position: absolute;
   bottom: 0;
   transition: background-color 0.5s ease-out;
 }

 .card button:focus {
   outline: none;
 }

 .card button:hover {
   background-color: #0ea8c7;
 }

 .text-info {
   padding: 1rem 1.5rem;
   margin-bottom: 2.5rem;
 }

 .carousel-container {
   margin-top: 10%;
 }

 .custom-right-arrow {
   position: absolute !important;
   right: 80px;
   z-index: 1;
   border: 1px solid grey;
   border-width: 0 7px 7px 0;
   display: inline-block;
   padding: 6px;
   opacity: 0.8;
   cursor: pointer;
   transform: rotate(-45deg);
 }

 .custom-left-arrow {
   position: absolute !important;
   left: 60px;
   z-index: 1;
   border: 1px solid grey;
   border-width: 0 7px 7px 0;
   display: inline-block;
   padding: 6px;
   opacity: 1;
   cursor: pointer;
   transform: rotate(135deg);
 }

 .glider-prev,
 .glider-next {
   width: 51px;
   top: 37%;
 }

 .glider-prev {
   left: -42px;
 }

 .glider-next {
   right: -45px;
 }

 .glider {
 @apply h-101;
 }

 .glider-contain {
   margin-bottom: 25px;
 }

 .glider-dots {
   margin-top: 15px;
 }

 .glider-track {
   height: 100%;
 }

 .gradient-outline {
   border: 3px solid !important;
   border-image: linear-gradient(to right, #8cc9f0, #efa8b0, #a89cc8) 5 !important;
 }

 .glider-slide {
   background: white;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #f5f5f5;
 }

 .glider-slide h1 {
   color: #8cc9f0;
   font-weight: bold;
 }

 .glider-slide:nth-child(2n) h1 {
   color: #a89cc8;
 }

 .glider-slide:nth-child(3n) h1 {
   color: #efa8b0;
 }


 .glider,
 .glider-contain {
   margin: 0 auto;
   position: relative
 }

 .glider,
 .glider-track {
   transform: translateZ(0)
 }

 .glider-dot,
 .glider-next,
 .glider-prev {
   border: 0;
   padding: 0;
   user-select: none;
   outline: 0
 }

 .glider-contain {
   width: 100%
 }

 .glider {
   overflow-y: hidden;
   -webkit-overflow-scrolling: touch;
   -ms-overflow-style: none
 }

 .glider-track {
   width: 100%;
   margin: 0;
   padding: 0;
   display: flex;
   z-index: 1
 }

 .glider.draggable {
   user-select: none;
   cursor: -webkit-grab;
   cursor: grab
 }

 .glider.draggable .glider-slide img {
   user-select: none;
   pointer-events: none
 }

 .glider.drag {
   cursor: -webkit-grabbing;
   cursor: grabbing
 }

 .glider-slide {
   user-select: none;
   justify-content: center;
   align-content: center;
   width: 100%;
   min-width: 150px
 }

 .glider-slide img {
   max-width: 100%
 }

 .glider::-webkit-scrollbar {
   opacity: 0;
   height: 0
 }

 .glider-next,
 .glider-prev {
   position: absolute;
   background: 0 0;
   z-index: 2;
   font-size: 40px;
   text-decoration: none;
   left: -23px;
   top: 30%;
   cursor: pointer;
   color: #666;
   opacity: 1;
   line-height: 1;
   transition: opacity .5s cubic-bezier(.17, .67, .83, .67), color .5s cubic-bezier(.17, .67, .83, .67)
 }

 .glider-next:focus,
 .glider-next:hover,
 .glider-prev:focus,
 .glider-prev:hover {
   color: #ccc
 }

 .glider-next {
   right: -23px;
   left: auto
 }

 .glider-next.disabled,
 .glider-prev.disabled {
   opacity: .25;
   color: #666;
   cursor: default
 }

 .glider-hide {
   opacity: 0
 }

 .glider-dots {
   user-select: none;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   margin: 0 auto;
   padding: 0
 }

 .glider-dot {
   display: block;
   cursor: pointer;
   color: #ccc;
   border-radius: 999px;
   background: #ccc;
   width: 12px;
   height: 12px;
   margin: 7px
 }

 .glider-dot:focus,
 .glider-dot:hover {
   background: #ddd
 }

 .glider-dot.active {
   background: #a89cc8
 }

 @media(max-width:36em) {
   .glider::-webkit-scrollbar {
     opacity: 1;
     width: 7px;
     height: 3px
   }

   .glider::-webkit-scrollbar-thumb {
     opacity: 1;
     border-radius: 99px;
     background-color: rgba(156, 156, 156, .25);
     -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .25);
     box-shadow: 0 0 1px rgba(255, 255, 255, .25)
   }
 }